import axios from '@/axios.js'

const state = () => ({
    agamas: []
})

const mutations = {
    SET_AGAMAS (state, payload) {
      state.agamas = payload
    },
    ADD_AGAMA (state, item) {
      state.agamas.unshift(item)
    },
    UPDATE_AGAMA (state, agama) {
      const agamaIndex = state.agamas.findIndex((p) => p.id === agama.id)
      Object.assign(state.agamas[agamaIndex], agama)
    },
    REMOVE_AGAMA (state, itemId) {
      const ItemIndex = state.agamas.findIndex((p) => p.id === itemId)
      state.agamas.splice(ItemIndex, 1)
    },
}

const actions = {
  fetchAgama ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`/term-list/agama`)
        .then((response) => {
          commit('SET_AGAMAS', response.data.data)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  addAgama ({ commit }, agama ) {
    return new Promise((resolve, reject) => {
      axios.post('/term-add', agama)
        .then((response) => {
          commit('ADD_AGAMA', response.data.data)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  updateAgama ({ commit }, agama) {
    return new Promise((resolve, reject) => {
      axios.post(`/term-update/${agama.id}`, agama)
        .then((response) => {
            commit('UPDATE_AGAMA', response.data.data)
            resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  updateFormAgama ({ commit }, agama) {
    return new Promise((resolve, reject) => {
      axios.post(`/term-update-form`, agama)
        .then((response) => {
            commit('UPDATE_AGAMA', response.data.data)
            resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  removeAgama ({ commit }, agama) {
    return new Promise((resolve, reject) => {
      axios.delete(`/term-delete/${agama.id}`)
        .then((response) => {
            commit('REMOVE_AGAMA', agama.id)
            resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  }
}

export default {
  isRegistered: false,
  namespaced: true,
  state,
  actions,
  mutations
}